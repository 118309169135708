export default {
  black: "#000",
  brightBlue: "#45aaf2",
  cyan: "#2bcbba",
  danger: "#ff5252",
  dark: "#0c0c0c",
  //   green: "#26de81",
  lightGreen: "#43cc68",
  grey: "#7b8ca4",
  highlightGreen: "#6de88e",
  highlight: "#e5e5e9",
  activeTab: "#6750a433",
  light: "#d4ffdf",
  light2: "#edfff2",
  lightGray: "#f8f4f4",
  lightGray2: "#d4d4d4",
  lightYellow: "#ffe66d",
  mediumGray: "#6e6969",
  orange: "#fd9644",
  primary: "#34a853",
  purple: "#9f75e6",
  red: "#fc5c65",
  secondary: "#4ecdc4",
  softBlue: "#4b7bec",
  white: "#fff",
  yellow: "#fed330",
  iosBackground: "#f2f2f6",
};
